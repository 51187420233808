import { provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID, importProvidersFrom, inject } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { ActivatedRoute, PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { appRoutes } from './app.routes';

import { SessionService, provideAuth, provideIcons, TranslocoHttpLoader } from '@kedi/core';
import { Meta } from '@angular/platform-browser';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { MessageService } from 'primeng/api';
//import { provideFuse } from '../@fuse';
import { provideFuse } from '@kedi/core/fuse';
import { provideNgxMask } from 'ngx-mask'

import { LocaleService } from 'ngx-daterangepicker-material'
import { provideTransloco, TranslocoService } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';


// import { provideAuth } from 'app/core/auth/auth.provider';
// import { provideIcons } from 'app/core/icons/icons.provider';
// import { provideTransloco } from 'app/core/transloco/transloco.provider';

export function loadSession(session: SessionService) {

    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    if (token) {
        session.setToken(token);
        window.history.replaceState({}, document.title, removeURLParameter(window.location.href, 'token'));

        //window.history.replaceState({}, document.title, "/" + "my-new-url.html");
    }
    return (): Promise<any> => { return session.load(); }
}

function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

var _privateError = console.error;
var _privateLog = console.log;
console.error = function (message) {
    let _message = message && message.message ? message.message : message;
    if (_message != "****************************************************************************************************************"
        && _message != "***************************************** AG Grid Enterprise License *******************************************"
        && _message != "****************************************** License Key Not Found ***********************************************"
        && _message != "* All AG Grid Enterprise features are unlocked.                                                                *"
        && _message != "* This is an evaluation only version, it is not licensed for development projects intended for production.     *"
        && _message != "* If you want to hide the watermark, please email info@ag-grid.com for a trial license.                        *"
        && !_message.startsWith("You must enable Billing on the Google Cloud Project at")
    ) {
        postMessage2KediMobile('error', arguments);
        _privateError.apply(console, arguments);
    }
};
console.log = function (message) {
    postMessage2KediMobile('log', arguments);
    _privateLog.apply(console, arguments);
}

if (window["kedimobile"] === true) {
    const onMessage = (event: MessageEvent<any>): void => {
        const message = JSON.parse(event.data) as IKediMobileMessage;
        if (!message && !message.code && message.fromMobile) {
            return;
        }

        if (message.code == "goback") {
            window.history.back();
        } else if (message.code == "signout") {
            window.location.href = '/sign-out';
        }
    };

    window.addEventListener('message', onMessage);
    document.addEventListener('message', onMessage);
}

interface IKediMobileMessage {
    code: string;
    message?: string;
    data?: any;
    fromMobile: boolean;
}

function postMessage2KediMobile(code: string, data?: any): void {
    if (window['kedimobile'] == true) {
        window['ReactNativeWebView'].postMessage(JSON.stringify(<IKediMobileMessage>{
            code: code,
            data: JSON.stringify(data),
            fromMobile: false,
        }));
    }
}

import localeTr from '@angular/common/locales/tr';

registerLocaleData(localeTr);

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: LOCALE_ID, useValue: 'tr' },
        Meta,
        DecimalPipe,
        DatePipe,
        MessageService,
        LocaleService,
        { provide: APP_INITIALIZER, useFactory: loadSession, deps: [SessionService], multi: true },
        importProvidersFrom(BrowserAnimationsModule),
        provideNgxMask(),
        provideAnimations(),
        provideHttpClient(),
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
        ),

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco({
            config: {
                availableLangs: [
                    {
                        id: 'tr',
                        label: 'Türkçe',
                    },
                    {
                        id: 'en',
                        label: 'English',
                    },
                ],
                defaultLang: 'tr',
                fallbackLang: 'tr',
                reRenderOnLangChange: true,
                prodMode: true,
            },
            loader: TranslocoHttpLoader,
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            useFactory: () => {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => firstValueFrom(translocoService.load(defaultLang));
            },
            multi: true,
        },

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            fuse: {
                layout: 'classy',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                        baseColor: null
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                        baseColor: null
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                        baseColor: null
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                        baseColor: null
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                        baseColor: null
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                        baseColor: null
                    },
                    {
                        id: 'theme-kedi',
                        name: 'Kedi Varsayılan',
                        baseColor: "#1565c0"
                    },
                    {
                        id: 'theme-ashan',
                        name: 'Aşhan',
                        baseColor: "#ddba7f"
                    },
                    {
                        id: 'theme-ihe1',
                        name: 'İHE Turuncu',
                        baseColor: "#de6f26"
                    },
                    {
                        id: 'theme-ihe2',
                        name: 'İHE Bordo',
                        baseColor: '#9A3324'
                    }
                ]
            },
        }),
    ],
};
