import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector     : 'not-found',
    templateUrl: './not-found.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class NotFoundPage
{
    constructor()
    {
    }

    ngOnInit(): void {
    }
}
