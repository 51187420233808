import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector     : 'default',
    template     : `<div></div>`,
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class DefaultPage
{
    constructor()
    {
    }

    ngOnInit(): void {
    }
}
