import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
// import { provideFuse } from '@kedi/core/fuse';
// import { appRoutes } from './app/app.routes';
// import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';

// import { provideHttpClient } from '@angular/common/http';
// import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID, inject } from '@angular/core';
// import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
// import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
// import { provideAnimations } from '@angular/platform-browser/animations';
// import { Meta } from '@angular/platform-browser';
// import { DatePipe, DecimalPipe } from '@angular/common';
// import { provideAuth, provideIcons, provideTransloco, SessionService } from '@kedi/core';
// import { MessageService } from 'primeng/api';

// export function loadSession(session: SessionService) {
//     return(): Promise<any> => { return session.load(); }
// }
// var _privateError = console.error;
// console.error = function (message) {
//     let _message = message && message.message ? message.message : message;
//     if (_message != "****************************************************************************************************************"
//      && _message != "***************************************** AG Grid Enterprise License *******************************************"
//      && _message != "****************************************** License Key Not Found ***********************************************"
//      && _message != "* All AG Grid Enterprise features are unlocked.                                                                *"
//      && _message != "* This is an evaluation only version, it is not licensed for development projects intended for production.     *"
//      && _message != "* If you want to hide the watermark, please email info@ag-grid.com for a trial license.                        *"
//      && !_message.startsWith("You must enable Billing on the Google Cloud Project at")
//     )
//     _privateError.apply(console, arguments);
// };

import { appConfig } from './app/app.config';
import "@angular/compiler";
bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));

// const _session = inject(SessionService);
// console.log(_session);
// _session.load();
// console.log(appRoutes);
/*
const appConfig: ApplicationConfig = {
    providers: [
        { provide: LOCALE_ID, useValue:'tr'},
        Meta,
        DecimalPipe,
        DatePipe,
        MessageService,
        { provide: APP_INITIALIZER, useFactory: loadSession, deps: [SessionService], multi: true},
        provideAnimations(),
        provideHttpClient(),
        provideRouter(appRoutes,
          withPreloading(PreloadAllModules),
          withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
        ),

        // Material Date Adapter
        {
            provide : DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide : MAT_DATE_FORMATS,
            useValue: {
                parse  : {
                    dateInput: 'D',
                },
                display: {
                    dateInput         : 'DDD',
                    monthYearLabel    : 'LLL yyyy',
                    dateA11yLabel     : 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco(),

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
          fuse   : {
              layout : 'classy',
              scheme : 'light',
              screens: {
                  sm: '600px',
                  md: '960px',
                  lg: '1280px',
                  xl: '1440px',
              },
              theme  : 'theme-default',
              themes : null
          },
        }),
    ],
};
console.log(appConfig);
*/
//bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));



