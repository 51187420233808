import { Route } from '@angular/router';
import { AnonymAuthGuard, AuthGuard, DefaultGuard, NoAuthGuard} from '@kedi/core';
import { LayoutComponent } from '@kedi/core/fuse';
import { DefaultPage } from './modules/default/default.component';
import LazyRoutes from './lazy.routes';
import PluginRoutes from './plugin.routes';
import { NotFoundPage } from './modules/not-found/not-found.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    { path: '', pathMatch : 'full', canActivate: [DefaultGuard], component: DefaultPage },
    { path: 'signed-in-redirect', pathMatch : 'full', canActivate: [DefaultGuard], component: DefaultPage },
    // {
    //     path: '',
    //     canActivate: [NoAuthGuard],
    //     canActivateChild: [NoAuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'confirmation-required', loadChildren: () => import('@kedi/core/pages/auth/confirmation-required/confirmation-required.routes')},
    //         { path: 'forgot-password', loadChildren: () => import('@kedi/core/pages/auth/forgot-password/forgot-password.routes')},
    //         { path: 'password-changed', loadChildren: () => import('@kedi/core/pages/auth/password-changed/password-changed.routes')},
    //     ]
    // },
    // {
    //     path: '',
    //     canActivate: [AnonymAuthGuard],
    //     canActivateChild: [AnonymAuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'reset-password', loadChildren: () => import('@kedi/core/pages/auth/reset-password/reset-password.routes')},
    //         { path: 'sign-in', loadChildren: () => import('@kedi/core/pages/auth/sign-in/sign-in.routes')},
    //         { path: 'sign-up', loadChildren: () => import('@kedi/core/pages/auth/sign-out/sign-out.routes')},
    //     ]
    // },
    // {
    //     path: '',
    //     canActivate: [AuthGuard],
    //     canActivateChild: [AuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'sign-out', loadChildren: () => import('@kedi/core/pages/auth/sign-out/sign-out.routes')},
    //         { path: 'unlock-session', loadChildren: () => import('@kedi/core/pages/auth/unlock-session/unlock-session.routes')}
    //     ]
    // },
    { 
        path: '',
        component: LayoutComponent,
        loadChildren: () => import('@kedi/core/pages').then(m => m.MainRoutes)
    },
    {
        path: 'core',
        component: LayoutComponent,
        loadChildren: () => import('@kedi/core/pages').then(m => m.CoreRoutes)
    },    
    ...LazyRoutes,
    ...PluginRoutes,
    {
        path: '**',
        component  : NotFoundPage,
    }
];
